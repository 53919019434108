define("ember-get-config/index", ["exports", "@embroider/macros/es-compat"], function (_exports, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var configModulePath = "".concat("kredits-web", "/config/environment");
  var _default = (0, _esCompat.default)(require(configModulePath)).default;
  _exports.default = _default;
});